import _define_property from "@swc/helpers/src/_define_property.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _object_spread_props from "@swc/helpers/src/_object_spread_props.mjs";
import { createTheme, responsiveFontSizes } from "@material-ui/core";
import merge from "lodash/merge";
import { getHeadingStyles, globalStyles } from "@aletheia/common/styles";
var PrimaryGWWC = "#BA175B" // purple-900
;
var SandGWWC = "#FAF4F4";
var palette = {
    primary: {
        main: PrimaryGWWC,
        light: "#F1D1DE",
        dark: "#9E144D",
        contrastText: "#FFF"
    },
    secondary: {
        main: "#BA2934",
        light: "#F1D4D6",
        dark: "#A11721",
        contrastText: "#FFF"
    },
    sand: {
        main: SandGWWC,
        light: "#fbedd4",
        dark: "#afa38d",
        contrastText: "#343434"
    }
};
/** Font stacks */ var sansSerifStack = '"Metropolis", "Metropolis-fallback", Helvetica, "Helvetica Neue", Arial, sans-serif';
/** Base styles for all heading tags */ var headingStyle = {
    fontFamily: sansSerifStack,
    fontWeight: 700
};
// Apply heading styles to all H<1...6> elements
var headings = getHeadingStyles(headingStyle);
/** Global styles to apply everywhere */ var themeGlobals = {};
/**
 * Global CSS declarations
 *
 * Built by recursively merging theme globals into common global styles
 */ var globals = merge({}, globalStyles, themeGlobals);
/** Override MUI components */ var overrides = {
    // Use the CSS baseline to create global styles
    MuiCssBaseline: {
        "@global": _object_spread({}, globals)
    },
    // @NOTE: This is a workaround to fix an issue with Safari 14.1.1
    // displaying the button color wrong after it changed to enabled state.
    //
    // See: https://github.com/mui-org/material-ui/issues/26251
    MuiButton: {
        root: {
            transition: "color .01s",
            textTransform: "none"
        }
    },
    MuiToolbar: {
        root: _define_property({}, "& .MuiButton-label", {
            fontSize: "16px",
            padding: "4px"
        })
    },
    MuiMenu: {
        paper: {
            borderRadius: 0
        }
    },
    MuiMenuItem: {
        root: _define_property({}, "& .MuiButton-label", {
            fontSize: "16px",
            padding: "4px"
        })
    },
    MuiAlert: {
        standardInfo: _define_property({
            backgroundColor: SandGWWC,
            color: PrimaryGWWC
        }, "& .MuiAlert-icon", {
            color: PrimaryGWWC
        })
    }
};
var theme = responsiveFontSizes(createTheme({
    props: {
        MuiButtonBase: {
            disableRipple: false
        }
    },
    typography: _object_spread_props(_object_spread({}, headings), {
        fontFamily: sansSerifStack,
        h3: {
            fontWeight: 100
        },
        h4: {
            fontWeight: 100
        },
        h5: {
            fontWeight: 100
        },
        h6: {
            fontWeight: 100
        }
    }),
    palette: palette,
    overrides: overrides
}));
export default theme;
